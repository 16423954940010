var Base = (function($){
	return {
		initLazy: function() {
			var bgImages = []

			// Lazy bg
			$('.lazy-bg').each(function(i, elem){
				var imageSrc = $(elem).data('bg-image')
				
				if ( imageSrc ) {
					var imgContainer = $('<div>').addClass('lazy-img-container').css('background-image', 'url(' + imageSrc + ')')
					$(elem).prepend(imgContainer)

					bgImages[i] = new Image()
					bgImages[i].src=imageSrc
					bgImages[i].onload=function(){
						$(elem).addClass('lazy-bg-loaded')
						$(elem).closest('.lazy-bg-container').addClass('lazy-bg-loaded')
					}
				}
			})
		},
		initHPSlider: function() {
			var hpSlider = $('#hpSlider')
			if ( hpSlider.size() > 0 ) {
				hpSlider.slick({
					arrows: false,
					fade: false,
					dots: true,
					autoplay: true,
					autoplaySpeed: 6000,
				})
			}
		},
		initNav: function() {
			$('.-js-hamburger').click(function(event) {
				/* Act on the event */
				$(this).toggleClass('is-active')
				$(this).closest('.nav').toggleClass('is-active')
			})
		},
		initMobileSections: function() {
			$(document).on('click tap', '.--wm-section', function(event) {
				event.preventDefault();

				if ( $(event.target).is('.btn.btn__arrow') ) {
					window.location.href = $(event.target).data('href')
				}
				else {
					$('.--wm-section').hide()
					if ( ! $(this).is('.toggled') ) {
						$(this).addClass('toggled')
						$(this).show()
					}
					else {
						$(this).removeClass('toggled')
						$('.--wm-section').show()
					}
				}
			});
		}
	}
})(jQuery)

jQuery(document).ready(function($) {
	Base.initLazy()
	Base.initHPSlider()
	Base.initMobileSections()
	Base.initNav()

	smoothScroll.init()

	$('.--eh-cols').matchHeight({ byRow: false })
	$('.--eh-group-1').matchHeight({ byRow: false })
	$('.--eh-group-2').matchHeight({ byRow: false })
	$('.--eh-group-3').matchHeight({ byRow: false })
	$('.--eh-group-4').matchHeight({ byRow: false })
	$('.--eh-group-5').matchHeight({ byRow: false })

	AOS.init({
		offset: 25,
		disable: 'mobile',
		once: true,
		duration: 600
	})
})